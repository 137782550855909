import axios from "axios";

const API_HOST =
  process.env.REACT_APP_API_HOST || "http://localhost:3000/api/v1";

const WP_API_HOST = "https://cms.taladpk.com/wp-json/wp/v2";

const getCategories = async () => {
  try {
    const response = await axios.get(
      WP_API_HOST + `/categories?_fields=id,name,slug`
    );

    if (response.status === 200) {
      return { categories: response.data };
    }
  } catch (error) {
    return { categories: [], isError: true };
  }
};

const getNews = async (
  page = 1,
  perPage = 4,
  categoryId = null,
  orderBy = "date",
  order = "desc"
) => {
  try {
    let endpoint =
      WP_API_HOST +
      `/posts?orderby=${orderBy}&order=${order}&per_page=${perPage}&page=${page}&_embed&_fields=_links,title,slug,date,excerpt,id,acf&acf_format=standard`;
    if (categoryId) {
      endpoint += "&categories=" + categoryId;
    }
    const response = await axios.get(endpoint);
    if (response.status === 200) {
      return { posts: response.data };
    }
  } catch (error) {
    return { posts: [], isError: true };
  }
};

const getNewsWithSlug = async (slug) => {
  try {
    const response = await axios.get(
      WP_API_HOST +
        `/posts?slug=${slug}&_embed&_fields=_links,title,slug,date,excerpt,id,content,acf&acf_format=standard`
    );

    if (response.status === 200) {
      return { post: response.data.length ? response.data[0] : null };
    }
  } catch (error) {
    return { post: null, isError: true };
  }
};

export { API_HOST, getNews, getNewsWithSlug, getCategories };
