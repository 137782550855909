import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import styled from "@emotion/styled";

import { API_HOST } from "../../api/index";
import axios from "axios";

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    axios.get(API_HOST + "/banners").then((res) => {
      if (res.status === 200) {
        setBanners(res.data.banners.filter((b) => b.image && b.image.fileurl));
      }
    });
  }, []);
  //const defaultImg = "holder.js/1440px400?bg=373940&text=&amp;";
  return (
    <>
      {banners.length > 0 && (
        <Carousel className="bg-secondary">
          {banners.map((b) => (
            <Carousel.Item>
              <center>
                <BannerImg
                  src={API_HOST + "/" + b.image.fileurl}
                  className="img-fluid"
                />
              </center>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default Banner;

const BannerImg = styled.img`
  // max-height: 600px;
  width: 100%;
  height: auto;
`;
