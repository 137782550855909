import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import NewsCardItem from "../components/share/NewsCardItem";
import InfiniteScroll from "react-infinite-scroller";
import { Filter as IFilter } from "react-feather";
import { getCategories, getNews } from "../api";

const NewsList = (props) => {
  const maxPerPage = 20;
  const [show, setShow] = useState(false);
  const [news, setNews] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [typeOptions, setTypeOptions] = useState([]);
  const [sort, setSort] = useState("desc");
  const [isChange, setIsChange] = useState(false);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState({
    type: "all",
    year: "2565",
  });

  const fetchNews = async (
    page = 1,
    per_page = maxPerPage,
    categoryId = null
  ) => {
    const _cate = categoryId && categoryId === "all" ? null : categoryId;
    const { posts } = await getNews(page, per_page, _cate, "date", sort);
    if (posts.length < per_page) setHasMore(false);
    else {
      setHasMore(true);
    }

    if (isChange) setNews(posts);
    else setNews(news.concat(posts));

    setPage(page + 1);
    setIsChange(false);
  };

  const fetchCategories = async () => {
    const { categories } = await getCategories();

    if (categories.length)
      setTypeOptions(
        [{ name: "ทั้งหมด", value: "all" }].concat(
          categories.map((c) => ({ name: c.name, value: c.id }))
        )
      );
  };

  useEffect(() => {
    fetchCategories();
    fetchNews();
  }, []);

  function sortNews(sort) {
    let _news = [].concat(news);
    if (sort === "asc") {
      _news.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
    } else {
      _news.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    }

    return _news;
  }

  useEffect(() => {
    const sortedNews = sortNews(sort);
    setNews(sortedNews);
  }, [sort]);

  useEffect(() => {
    setIsChange(true);
    setPage(1);
  }, [search]);

  const getYearOptions = () => {
    let opts = [{ value: "2563", txt: "2563" }];
    opts.push({ value: "2564", txt: "2564" });
    opts.push({ value: "2565", txt: "2565" });

    return opts.map((t) => {
      return <option value={t.value}>{t.txt}</option>;
    });
  };

  const Filter = () => {
    return (
      <>
        <h4 className="tk mt-2">ตัวกรองข้อมูล</h4>
        <hr />
        <Form.Label htmlFor="newsTypeSearch" className="tk">
          ประเภทข่าวสาร
        </Form.Label>
        <Form.Select
          id="newsTypeSearch"
          className="tk"
          onChange={(e) => setSearch({ ...search, type: e.target.value })}
          defaultValue={search.type}
        >
          {typeOptions.map((t) => (
            <option value={t.value}>{t.name}</option>
          ))}
        </Form.Select>
        {/* <Form.Label htmlFor="newsYearSearch" className="tk"></Form.Label>
        <Form.Select
          id="newsYearSearch"
          className="tk"
          onChange={(e) => setSearch({ ...search, year: e.target.value })}
          defaultValue={search.year}
        >
          {getYearOptions()}
        </Form.Select> */}
        <Button
          variant="danger"
          className="tk mt-3 w-100"
          onClick={() => {
            if (isChange) fetchNews(1, maxPerPage, search.type);
            setShow(false);
          }}
        >
          ค้นหา
        </Button>
      </>
    );
  };

  return (
    <>
      <Container className="mb-3">
        <center>
          <h1 className="text-danger mb-5 mt-2">ข่าวสารในตลาด</h1>
        </center>
        <Row>
          <Col xs={12}>
            <Row className="justify-content-between mt-1 mb-1">
              <Col xs={4} sm={2} md={2} lg={2}>
                <Button
                  variant="danger"
                  className="tk"
                  onClick={() => setShow(true)}
                >
                  <IFilter />{" "}
                  <span className="d-none d-md-inline tk">กรอง</span>
                </Button>
              </Col>
              <Col xs={8} sm={6} md={6} lg={3}>
                <Form.Select
                  className="tk"
                  value={sort}
                  onChange={(e) => setSort(e.target.value)}
                >
                  <option value="desc">วันที่ล่าสุดไปเก่าสุด</option>
                  <option value="asc">วันที่เก่าสุดไปล่าสุด</option>
                </Form.Select>
              </Col>
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
            {news.length > 0 ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  fetchNews(page, maxPerPage, search.type);
                }}
                hasMore={hasMore}
                loader={
                  <center>
                    <Spinner
                      animation="border"
                      variant="danger"
                      className="mt-2 mb-2"
                      size="lg"
                    />
                  </center>
                }
              >
                <Row className="row-cols-1 row-cols-md-4 gx-1 gy-2 mb-2">
                  {news.map((n, i) => (
                    <Col key={`news-${i}`}>
                      <NewsCardItem post={n} maxWidth="100%" />
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            ) : (
              <Container
                fluid
                style={{ height: "100px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="text-secondary text-muted">
                  ไม่พบข้อมูลที่ท่านต้องการ
                </span>
              </Container>
            )}
          </Col>
        </Row>
      </Container>

      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{Filter()}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default NewsList;
