import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import CNavbar from "./components/CNavbar";
import Footer from "./components/Footer";
import ProductList from "./pages/ProductList";
import ScrollToTop from "./components/ScrollToTop";
import NewsList from "./pages/NewsList";
import { Container } from "react-bootstrap";
import MapMarket from "./pages/MapMarket";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";
import News from "./pages/News";
import PrivacyNotice from "./pages/PrivacyNotice";
import History from "./pages/History";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <CNavbar />
        {/* <Container fluid style={{ maxWidth: "2500px" }}> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:id" element={<Product />} />
          <Route path="/news" element={<NewsList />} />
          <Route path="/news/:slug" element={<News />} />
          <Route path="/map" element={<MapMarket />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacyNotice" element={<PrivacyNotice />} />
          <Route path="/history" element={<History />} />
        </Routes>
        {/* </Container> */}
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
