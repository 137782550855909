import React from "react";

import styled from "@emotion/styled";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Facebook } from "react-bootstrap-icons";
import { Line } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <ContainerBlack className="w-100 d-flex justify-content-center align-items-end">
        <Container style={{ backgroundColor: "#373940" }} className="mt-4">
          <Row>
            <Col xs={12} md={3} className="d-none d-sm-block">
              <p className="fs-4 fw-bold text-danger tk">เมนู</p>
              <p className="fs-6 text-white tk">
                <NavLink to="/" className="text-white text-decoration-none">
                  หน้าแรก
                </NavLink>
              </p>
              <p className="fs-6 text-white tk">
                <NavLink
                  to="/products"
                  className="text-white text-decoration-none"
                >
                  ราคากลาง
                </NavLink>
              </p>
              <p className="fs-6 text-white tk">
                <NavLink to="/news" className="text-white text-decoration-none">
                  ข่าวสาร
                </NavLink>
              </p>
              <p className="fs-6 text-white tk">
                <NavLink to="/map" className="text-white text-decoration-none">
                  แผนที่
                </NavLink>
              </p>
              <p className="fs-6 text-white tk">
                <NavLink
                  to="/history"
                  className="text-white text-decoration-none"
                >
                  ความเป็นมาของตลาด
                </NavLink>
              </p>
            </Col>
            <Col xs={12} md={3}>
              <p className="fs-4 fw-bold text-danger tk">พันธมิตร</p>
              <p className="fs-6">
                <a
                  href="https://www.facebook.com/pathomnakornmarket/"
                  className="text-white tk text-decoration-none"
                >
                  ตลาดปฐมนคร
                </a>
              </p>
              <p className="fs-6">
                <a
                  href="https://www.facebook.com/pathommongkolmarket/"
                  className="text-white tk text-decoration-none"
                >
                  ตลาดปฐมมงคล
                </a>
              </p>
              <p className="fs-6">
                <a
                  href="https://www.facebook.com/pathommongkolmarket/"
                  className="text-white tk text-decoration-none"
                >
                  ปุ๋ยอินทรีย์ P.K. ตราห้าม้าแข่ง
                </a>
              </p>
              <p className="fs-6">
                <a
                  href="https://www.facebook.com/pathommongkolmarket/"
                  className="text-white tk text-decoration-none"
                >
                  ปั้มน้ำมัน
                </a>
              </p>
            </Col>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={3}>
              <p className="fs-4 fw-bold text-danger tk">ติดต่อเรา</p>
              <p className="fs-6 text-white tk">ตลาดปฐมมงคล ตลาดผักผลไม้กลาง</p>
              <p className="fs-5 text-danger tk">ลูกค้าสัมพันธ์ (สายด่วน)</p>
              <p className="fs-6 text-white tk">
                <a
                  className="btn btn-text text-white p-0"
                  href="tel://0818516225"
                >
                  โทร : 081-851-6225
                </a>
              </p>
              <p className="fs-6 text-white tk">
                อีเมล : chairat9854@gmail.com
              </p>
              <p className="fs-6 text-white tk">
                ที่อยู่ : 129 ถนนทวาราวดี ห้วยจรเข้ เมืองนครปฐม นครปฐม 73000
              </p>
              <p>
                <a
                  className="btn btn-text"
                  href="https://line.me/ti/p/%40pkmarket"
                >
                  <Facebook size={"24px"} className="text-white" />
                </a>
                &nbsp;&nbsp;&nbsp;
                <a
                  className="btn btn-text"
                  href="https://www.facebook.com/pathommongkolmarket"
                >
                  <Line size={"24px"} className="text-white" />
                </a>
              </p>
            </Col>
          </Row>
          <Row className="mb-2">
            <span
              className="fs-6 text-white tk text-center pointer"
              onClick={() => navigate("/privacyNotice")}
            >
              คำประกาศความเป็นส่วนตัว
            </span>
          </Row>
        </Container>
      </ContainerBlack>
      <CopyRight className="tk bg-danger text-white d-flex align-items-center justify-content-center">
        สงวนลิขสิทธิ์ ตลาดปฐมมงคล 2565
      </CopyRight>
    </>
  );
};

const ContainerBlack = styled.div`
  background-color: #373940;
`;

const CopyRight = styled.div`
  height: 70px;
`;

export default Footer;
