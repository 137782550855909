import React from "react";
import LINE_ICON from "../assets/icons8-line.svg";
import FACEBOOK_ICON from "../assets/icons8-facebook.svg";
import { Phone } from "react-feather";
import { Container } from "react-bootstrap";

const ContactUs = () => {
  return (
    <Container>
      <center>
        <h1 className="tk mt-3 text-danger">ติดต่อเรา</h1>
      </center>
      <iframe
        width="100%"
        height="600px"
        style={{ border: "0" }}
        loading="lazy"
        allowFullScreen
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDiDaf56BlpOEr9_UhnjqtQSZoQPre5sDI
    &q=Talat+Pathom+Mongkol(Fruits)"
      ></iframe>
      <div className="mt-5 mb-5">
        <h3 className="tk text-danger">ตลาดปฐมมงคล ตลาดผักผลไม้กลาง</h3>
        <p>129 ถนนทวาราวดี ห้วยจรเข้ เมืองนครปฐม นครปฐม 73000</p>
        <a className="btn btn-text ts" href="tel://0818516225">
          <Phone /> 081-851-6225
        </a>
        <br />
        <a href="https://line.me/ti/p/%40pkmarket">
          <img src={LINE_ICON} />
        </a>

        <a href="https://www.facebook.com/pathommongkolmarket">
          <img src={FACEBOOK_ICON} />
        </a>

        {/* <img
          src={QR_CODE_LINE}
          style={{ height: "200px" }}
          className="d-none d-sm-block"
        ></img> */}
      </div>
    </Container>
  );
};

export default ContactUs;
