import React from "react";
import { formatValue } from "react-currency-input-field";

const NumberText = ({ value, decimalScale }) => {
  return (
    <>
      {formatValue({
        value: value + "",
        decimalScale,
      })}
    </>
  );
};

export default NumberText;
