import React from "react";

import { Card, Badge } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { API_HOST } from "../../api";
import LOGO from "../../logo.svg";
import { toThaiDateString } from "../../utils";
import NumberText from "../NumberText";

const CardItem = ({ product }) => {
  const navigate = useNavigate();
  const { id, name, images, unit, type, priceCheckItems } = product;

  const getType = () => {
    if (type === "fruit") return "ผลไม้";
    if (type === "vegetable") return "ผัก";

    return null;
  };

  const getDate = () => {
    if (
      priceCheckItems &&
      priceCheckItems.length &&
      priceCheckItems[0].priceCheck &&
      priceCheckItems[0].priceCheck.priceAt
    ) {
      return toThaiDateString(priceCheckItems[0].priceCheck.priceAt);
    }

    return "-";
  };

  const getPrice = () => {
    let minPrice = null;
    let maxPrice = null;
    if (
      priceCheckItems &&
      priceCheckItems.length &&
      priceCheckItems[0].minPrice
    ) {
      minPrice = priceCheckItems[0].minPrice;
    }

    if (
      priceCheckItems &&
      priceCheckItems.length &&
      priceCheckItems[0].maxPrice
    ) {
      maxPrice = priceCheckItems[0].maxPrice;
    }

    if (minPrice && maxPrice && minPrice === maxPrice) {
      return (
        <Card.Text className="fs-5 tk fw-bold text-danger">
          <NumberText value={minPrice} /> บาท {unit && " / " + unit}
        </Card.Text>
      );
    }

    if (minPrice && !maxPrice)
      return (
        <Card.Text className="fs-5 tk fw-bold text-danger">
          <NumberText value={minPrice} /> บาท {unit && " / " + unit} ขึ้นไป
        </Card.Text>
      );
    if (!minPrice && maxPrice)
      return (
        <Card.Text className="fs-5 tk fw-bold text-danger">
          สูงสุด <NumberText value={maxPrice} /> บาท {unit && " / " + unit}
        </Card.Text>
      );
    if (!minPrice && !maxPrice) return "-";

    return (
      <Card.Text className="fs-5 tk fw-bold text-danger">
        <NumberText value={minPrice} /> - <NumberText value={maxPrice} /> บาท
        {unit && " / " + unit}
      </Card.Text>
    );
  };

  return (
    <Card
      className="shadow-sm my-1 h-100 rounded-5"
      style={{ cursor: "pointer" }}
      onClick={() => navigate("/products/" + id)}
    >
      <Card.Img
        variant="bottom"
        className="mt-1"
        src={
          Array.isArray(images) && images.length > 0 && images[0].fileurl
            ? API_HOST + "/" + images[0].fileurl
            : LOGO
        }
        style={
          Array.isArray(images) && images.length > 0 && images[0].fileurl
            ? { width: "100%", height: "auto", maxHeight: "230px" }
            : {
                width: "100%",
                padding: "20px 30px 0px 30px",
                maxHeight: "230px",
              }
        }
      />
      <Card.Body>
        <Card.Title className="fs-6">{name}</Card.Title>
        <Card.Text>
          <Badge bg="danger">{getType()}</Badge>
        </Card.Text>
        {getPrice()}
      </Card.Body>
      <Card.Footer className="text-muted ts">
        <small>{getDate()}</small>
      </Card.Footer>
    </Card>
  );
};

export default CardItem;
