import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Offcanvas,
  Spinner,
} from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import CardItem from "../components/share/CardItem";
import InfiniteScroll from "react-infinite-scroller";
import { Filter as IFilter } from "react-feather";
import axios from "axios";
import { API_HOST } from "../api";

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

const ProductList = () => {
  const ALL = 300;
  const maxPerPage = 20;
  const [show, setShow] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchParams, _] = useSearchParams();
  const [products, setProducts] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [sortType, setSortType] = useState("min");
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState({
    name: "",
    type: searchParams.get("type") !== null ? searchParams.get("type") : "all",
  });

  const getProducts = async (
    limit = maxPerPage,
    offset = 0,
    query,
    type,
    isChange = false
  ) => {
    try {
      let endpoint = API_HOST + `/products?limit=${limit}&offset=${offset}`;
      if (query) {
        endpoint += `&query=${query}`;
      }
      if (type && type !== "all") {
        endpoint += `&type=${type}`;
      }
      const response = await axios.get(endpoint);
      if (response.status === 200) {
        if (response.data.products.length < limit) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        let _products = isChange
          ? [].concat(response.data.products)
          : products.concat(response.data.products);
        sortedProducts(sortType, _products);
        setProducts(_products);
        setIsChange(false);
        setPage(page + 1);
      }
    } catch (error) {
      setIsChange(false);
      setPage(page + 1);
    }
  };

  function getPrice(priceCheckItems) {
    let minPrice = 0;
    let maxPrice = 0;
    if (
      priceCheckItems &&
      priceCheckItems.length &&
      priceCheckItems[0].minPrice
    ) {
      minPrice = priceCheckItems[0].minPrice;
    }

    if (
      priceCheckItems &&
      priceCheckItems.length &&
      priceCheckItems[0].maxPrice
    ) {
      maxPrice = priceCheckItems[0].maxPrice;
    }
    return { minPrice, maxPrice };
  }

  useEffect(() => {
    getProducts(
      ALL,
      page,
      null,
      searchParams.get("type") !== null ? searchParams.get("type") : "all",
      false
    );
  }, []);

  useEffect(() => {
    setIsChange(true);
    setPage(0);
  }, [search]);

  useEffect(() => {
    let _products = [].concat(products);
    sortedProducts(sortType, _products);
    setProducts(_products);
  }, [sortType]);

  function sortedProducts(type, _products) {
    if (type === "min") {
      _products.sort((a, b) => {
        const { minPrice: aMinPrice, maxPrice: aMaxPrice } = getPrice(
          a.priceCheckItems
        );
        const { minPrice: bMinPrice, maxPrice: bMaxPrice } = getPrice(
          b.priceCheckItems
        );

        if (aMinPrice && bMinPrice) {
          if (aMinPrice === bMinPrice) {
            return aMaxPrice - bMaxPrice;
          }
          return aMinPrice - bMinPrice;
        }
        if (aMaxPrice && bMaxPrice) {
          return aMaxPrice - bMaxPrice;
        }

        if (aMinPrice && bMaxPrice) {
          return aMinPrice - bMaxPrice;
        }

        if (bMinPrice && aMaxPrice) {
          return aMaxPrice - bMinPrice;
        }
        return 1;
      });
      _products.sort((a, b) => {
        const { minPrice: aMinPrice, maxPrice: aMaxPrice } = getPrice(
          a.priceCheckItems
        );
        const { minPrice: bMinPrice, maxPrice: bMaxPrice } = getPrice(
          b.priceCheckItems
        );
        if (aMinPrice && aMaxPrice && !bMinPrice && !bMaxPrice) {
          return -1;
        }
        if (!aMinPrice && !aMaxPrice && bMinPrice && bMaxPrice) {
          return 1;
        }

        return 0;
      });
    }
    if (type === "max") {
      _products.sort((a, b) => {
        const { minPrice: aMinPrice, maxPrice: aMaxPrice } = getPrice(
          a.priceCheckItems
        );
        const { minPrice: bMinPrice, maxPrice: bMaxPrice } = getPrice(
          b.priceCheckItems
        );
        if (aMaxPrice && bMaxPrice) {
          if (aMaxPrice === bMaxPrice) {
            return bMinPrice - aMinPrice;
          }
          return bMaxPrice - aMaxPrice;
        }
        if (bMinPrice && aMinPrice) {
          return bMinPrice - aMinPrice;
        }

        if (bMaxPrice && aMinPrice) {
          return bMaxPrice - aMinPrice;
        }

        if (aMaxPrice && bMinPrice) {
          return aMaxPrice - bMinPrice;
        }

        // if (aMaxPrice && bMaxPrice) {
        //   return bMaxPrice - aMaxPrice;
        // }
        // if (aMaxPrice && !bMaxPrice) {
        //   return bMinPrice - aMaxPrice;
        // }
        // if (!aMaxPrice && bMaxPrice) {
        //   return bMaxPrice - aMinPrice;
        // }
        // if (aMinPrice && bMinPrice) {
        //   return bMinPrice - aMinPrice;
        // }
        return 0;
      });
    }
    if (type === "asc") {
      _products.sort(compare);
    }
  }

  const Filter = () => {
    return (
      <>
        <h4 className="tk mt-2">ตัวกรองข้อมูล</h4>
        <hr />
        <Form.Label htmlFor="productSearch" className="tk">
          ชื่อสินค้า
        </Form.Label>
        <Form.Control
          type="text"
          id="productSearch"
          // aria-describedby="passwordHelpBlock"
          className="tk"
          name="productSearch"
          onChange={(e) => setSearch({ ...search, name: e.target.value })}
          defaultValue={search.name}
        />
        <Form.Label htmlFor="productTypeSearch" className="tk">
          ประเภทสินค้า
        </Form.Label>
        <Form.Select
          id="productTypeSearch"
          className="tk"
          onChange={(e) => setSearch({ ...search, type: e.target.value })}
          defaultValue={search.type}
        >
          <option value="all">ทั้งหมด</option>
          <option value="fruit">ผลไม้</option>
          <option value="vegetable">ผัก</option>
        </Form.Select>
        <Button
          variant="danger"
          className="tk mt-3 w-100"
          onClick={() => {
            setShow(false);
            if (isChange) {
              getProducts(ALL, 0, search.name, search.type, true);
            }
          }}
        >
          ค้นหา
        </Button>
      </>
    );
  };

  return (
    <>
      <Container className="mb-3">
        <center>
          <h1 className="text-danger mb-5 mt-2">ราคากลาง</h1>
        </center>
        <Row>
          <Col xs={12}>
            <Row className="justify-content-between mt-1 mb-1">
              <Col xs={4} sm={2} md={2} lg={2}>
                <Button
                  variant="danger"
                  className="tk"
                  onClick={() => setShow(true)}
                >
                  <IFilter />{" "}
                  <span className="d-none d-md-inline tk">กรอง</span>
                </Button>
              </Col>
              <Col xs={8} sm={6} md={6} lg={3}>
                <Form.Select
                  className="tk"
                  onChange={(e) => setSortType(e.target.value)}
                  value={sortType}
                >
                  <option value="min">ราคาต่ำสุดไปสูงสุด</option>
                  <option value="max">ราคาสูงสุดไปต่ำสุด</option>
                  <option value="asc">เรียงตามตัวอักษรของชื่อสินค้า</option>
                </Form.Select>
              </Col>
            </Row>
            {products.length > 0 ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  getProducts(maxPerPage, page, search.name, search.type);
                }}
                hasMore={hasMore}
                loader={
                  <center>
                    <Spinner
                      animation="border"
                      variant="danger"
                      className="mt-2 mb-2"
                      size="lg"
                    />
                  </center>
                }
              >
                <Row className="row-cols-2 row-cols-md-3 row-cols-lg-4 gx-1 gy-2 mb-2">
                  {products.map((p, i) => (
                    <Col key={`product-${i}`}>
                      <CardItem product={p} key={`cartItem-${i}-p`} />
                    </Col>
                  ))}
                </Row>
              </InfiniteScroll>
            ) : (
              <Container
                fluid
                style={{ height: "100px" }}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="text-secondary text-muted">
                  ไม่พบข้อมูลที่ท่านต้องการ
                </span>
              </Container>
            )}
          </Col>
        </Row>
      </Container>

      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{Filter()}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProductList;
