import React from "react";
import { Building } from "react-bootstrap-icons";

const style = {
  width: "100%",
  height: "150px",
  borderRadius: "21px 21px 0 0",
};

const BuildingCard = ({ name, description }) => {
  return (
    <div className="w-100 my-md-3 h-100 pointer border border-danger">
      <div className="bg-light h-100 pt-3 pt-md-5 text-center overflow-hidden">
        <div className="my-3 py-3">
          <h2 className="display-6 text-danger">{name}</h2>
          <p className="lead text-muted ts fs-6">{description}</p>
        </div>
        {/* <div
          className="bg-white h-100 shadow-sm mx-auto text-danger"
          style={style}
        >
          <Building size={"100px"} className="mt-5" />
        </div> */}
      </div>
    </div>
  );
};

export default BuildingCard;
