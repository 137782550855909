import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import Banner from "../components/home/Banner";
import styled from "@emotion/styled";
import CardItem from "../components/share/CardItem";
import BANNER from "../assets/banner-example.webp";
import BANNER2 from "../assets/banner-example2.webp";
// import WANTTORENT from "../assets/wanttorent.gif";

import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "axios";
import { API_HOST, getNews } from "../api";
import NewsCardItem from "../components/share/NewsCardItem";
import Partner1 from "../assets/partner/Logoปฐมนคร.jpg";
import Partner2 from "../assets/partner/Logoปฐมมงคล.jpg";
import Partner3 from "../assets/partner/Logoปุ๋ย.webp";
import Partner4 from "../assets/partner/Logoห้องเย็น.webp";
import { Image } from "react-bootstrap";
import { Card } from "react-bootstrap";

const Home = () => {
  const navigate = useNavigate();

  const [fruits, setFruits] = useState([]);
  const [vegetables, setVegetables] = useState([]);
  const [news, setNews] = useState([]);

  const goTo = (path) => {
    navigate(path);
  };

  const getSomeNews = async () => {
    const { posts } = await getNews();

    if (posts.length) {
      setNews(posts);
    }
  };

  useEffect(() => {
    const offsetF = Math.floor(Math.random() * 7);
    const offsetV = Math.floor(Math.random() * 6);
    axios
      .get(API_HOST + "/products?limit=4&type=fruit&offset=" + offsetF)
      .then((res) => {
        if (res.status === 200) {
          setFruits(res.data.products);
        }
      })
      .catch((error) => {});

    axios
      .get(API_HOST + "/products?limit=4&type=vegetable&offset=" + offsetV)
      .then((res) => {
        if (res.status === 200) {
          setVegetables(res.data.products);
        }
      })
      .catch((error) => {});

    getSomeNews();
  }, []);

  return (
    <>
      <Banner />
      {/* <Container fluid> */}
      <div
        className="bg-danger d-flex justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <h1 className="text-center text-white" style={{ fontSize: "3.75rem" }}>
          ราคากลาง สินค้าในตลาด
        </h1>
      </div>
      <div className="bg-light w-100">
        <HeadFruit className="d-flex justify-content-center align-items-center mb-3">
          <h1 className="h1 fs-1 fw-bold text-danger">ผลไม้</h1>
        </HeadFruit>
        <Container>
          <Row className="row-cols-2 row-cols-sm-3 row-cols-lg-4 g-0 gx-2 gy-2">
            {fruits.map((f) => (
              <Col>
                <CardItem product={f} />
              </Col>
            ))}
          </Row>
          <div className="text-center text-sm-end mt-3 mb-3">
            <Button
              variant="light"
              className="text-danger tk"
              size="lg"
              onClick={() => goTo("/products?type=fruit")}
            >
              ดูทั้งหมด
            </Button>
          </div>
        </Container>
      </div>

      <HeadVeget className="d-flex justify-content-center align-items-center mb-3">
        <h1 className="text-danger fw-bold">ผัก</h1>
      </HeadVeget>
      <Container>
        <Row className="row-cols-2 row-cols-sm-3 row-cols-lg-4 g-0 gx-2 gy-2">
          {vegetables.map((f) => (
            <Col>
              <CardItem product={f} />
            </Col>
          ))}
        </Row>
        <div className="text-center text-sm-end mt-3 mb-3">
          <Button
            variant="light"
            className="text-danger tk"
            size="lg"
            onClick={() => goTo("/products?type=vegetable")}
          >
            ดูทั้งหมด
          </Button>
        </div>
      </Container>

      <Row className="row-cols-1 row-cols-sm-2 gx-0 gy-1">
        <Col>
          <img src={BANNER} width="100%" />
        </Col>
        <Col>
          <img src={BANNER2} width="100%" />
        </Col>
      </Row>
      <Container className="mt-3">
        <h1 className="h1 fs-1 fw-bold text-danger fw-lighter">
          ข่าวสารในตลาด
        </h1>
        <AliceCarousel
          mouseTracking
          disableButtonsControls
          disableDotsControls
          autoWidth
          items={news.map((n) => (
            <NewsCardItem post={n} />
          ))}
          responsive={{
            0: {
              items: 1,
            },
            768: {
              items: 2,
            },
            1024: {
              items: 3,
            },
          }}
        />
        <div className="text-center text-sm-end mt-1 mb-3">
          <Button
            variant="light"
            className="text-danger tk"
            size="lg"
            onClick={() => goTo("/news")}
          >
            ดูทั้งหมด
          </Button>
        </div>
      </Container>
      <hr />
      <Container className="mb-2">
        <center>
          <span className="fs-3 text-danger tk text-center pointer">
            พันธมิตร
          </span>
        </center>

        <Row className="g-0">
          <Col></Col>
          <Col xs={12} sm={6} md={2} className="pointer">
            <a href="https://www.facebook.com/pathomnakornmarket/">
              <Image src={Partner1} className="mt-1 w-100" />
            </a>
          </Col>
          <Col xs={12} sm={6} md={2} className="pointer">
            <a href="https://www.facebook.com/pathommongkolmarket/">
              <Image src={Partner2} className="mt-1 w-100" />
            </a>
          </Col>
          <Col xs={12} sm={6} md={2} className="pointer">
            <a href="https://www.facebook.com/pathommongkolmarket/">
              <Image src={Partner3} className="mt-1 w-100" />
            </a>
          </Col>
          <Col xs={12} sm={6} md={2} className="pointer">
            <a href="https://www.facebook.com/pathommongkolmarket/">
              <Image src={Partner4} className="mt-1 w-100" />
            </a>
          </Col>
          <Col xs={12} sm={6} md={2} className="pointer">
            <a
              href="https://www.facebook.com/pathommongkolmarket/"
              className="btn btn-text w-100 h-100"
            >
              {/* <Image src={Partner4} className="mt-1 w-100" /> */}
              <Card className="w-100 h-100">
                <Card.Body className="d-flex justify-content-center align-items-center tk">
                  ปั้มน้ำมัน เร็วๆนี้
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col></Col>
        </Row>
      </Container>
      {/* </Container> */}
    </>
  );
};

export default Home;

const HeadFruit = styled.div`
  // opacity: 0.6;
  // background-blend-mode: multiply;
  height: 150px;
  // background-repeat: no-repeat;
  // background-size: cover;
`;

const HeadVeget = styled(HeadFruit)``;
