import React from "react";
import { Container } from "react-bootstrap";

const History = () => {
  return (
    <Container fluid className="tk mt-3 mb-3">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ตลาดปฐมมงคล อ.เมือง จ.นครปฐม ณ.
      วันอาทิตย์ที่ 8 พ.ย. 43 เวลา 18.39 น. ลัคณาสถิตย์อยู่ราศีมีน ราชาแห่งฤกษ์
      ประธานฝ่ายสงฆ์ สมเด็จพระมหาธีราจารย์ (นิยม) วัดชนะสงคราม กทม.
      เจ้าคณะหนกลาง ประธานฝ่ายฆราวาส พลตำรวจตรี พลศรี และคุณทิพาพร ชวนไชยสิทธิ์
      จัดสร้างโดย คุณสุชาติ คุณสายจิต วิบูลมงคล คุณสุชาติ คุณศิริพร
      เชื่อมวราศาสตร์ พร้อมด้วยญาติมิตรผู้มีจิตศรัทธา ประวัติตลาดปฐมมงคล
      สถานที่ตลาดปฐมมงคลนี้เป็นที่ดินของคฤหบดีบรรดาศักดิ์ท่านหนึ่ง
      ซึ่งยังไม่ได้จัดทำประโยชน์ชนิดถาวร ที่สมควร คุณพ่อฉอ้อน คุณแม่เพ็ญแข
      จงสงวน เป็นคนจังหวัดสงขลา มานมัสการ พระปฐมเจดีย์ ได้พบที่ดิน โล่งนี้
      ชอบใจมาก จึงติดต่อขอซื้อ โดยตั้งเจตนารมณ์ว่า จะสร้างเป็นตลาด
      เพื่อเป็นที่ค้าขายของคนนครปฐม และคนจังหวัดใกล้เคียง แต่ขณะนั้นไม่มีจังหวะ
      และโอกาสที่ดี เพราะมีธุรกิจอยู่หลายแห่งจึงไม่ได้ทำ
      มาบัดนี้มอบให้ทางญาติจัดสร้างตลาดตามเจตนารมณ์
      ที่ตนเองตั้งปณิธานไว้แต่ครั้งแรก ตลาดปฐมมงคล
      สร้างเพื่อเทอดพระเกียรติในหลวงครองสิริราชสมบัติครบ 50 ปี โดยคุณสุชาติ
      คุณสายจิต วิบูลมงคล และคุณสุชาติ คุณศิริพร เชื่อมวราศาสตร์
    </Container>
  );
};

export default History;
