import React, { useEffect } from "react";
import { Container, Nav, NavDropdown, Button, Navbar } from "react-bootstrap";
import styled from "@emotion/styled";

import logo from "../logo.svg";

import { NavLink } from "react-router-dom";
import { Home } from "react-feather";
import { Book } from "react-feather";
import { Map } from "react-feather";
import { Archive } from "react-feather";
import { Inbox } from "react-feather";
import { Phone } from "react-feather";
import { Line, Facebook } from "react-bootstrap-icons";
import hero from "../assets/hero.webp";
import { DollarSign } from "react-feather";

const CNavbar = () => {
  const mobileMenuRef = React.createRef();

  return (
    <>
      <ONavbar sticky="top" bg="danger" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand className="d-lg-none">
            <img
              src={logo}
              width="60px"
              height="60px"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <div className="text-white ns d-lg-none fs-5">ตลาดปฐมมงคล</div>
          <Navbar.Toggle aria-controls="navbarScroll" ref={mobileMenuRef} />
          <Navbar.Collapse id="navbarScroll">
            <div
              onClick={() => {
                mobileMenuRef.current.click();
              }}
            >
              <Nav
                className="me-auto my-2 my-lg-0"
                // style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <NavLink to="/" className="nav-link text-white tk">
                  <Home /> หน้าแรก
                </NavLink>

                <NavLink to="/products" className="nav-link text-white tk">
                  <DollarSign /> ราคากลาง
                </NavLink>
                <NavLink to="/news" className="nav-link text-white tk">
                  <Book /> ข่าวสาร
                </NavLink>
                <NavLink to="/map" className="nav-link text-white tk">
                  <Map /> แผนที่
                </NavLink>
                <NavLink to="/history" className="nav-link text-white tk">
                  <Archive /> ความเป็นมาของตลาด
                </NavLink>
                <NavLink to="/contactUs" className="nav-link text-white tk">
                  <Inbox /> ติดต่อเรา
                </NavLink>
              </Nav>
            </div>

            {/* <Nav>
              <Nav.Link className="text-white">TH</Nav.Link>
              <Nav.Link disabled>|</Nav.Link>
              <Nav.Link className="text-white">EN</Nav.Link>
            </Nav> */}
            {/* <Button variant="outline-danger"></Button> */}
          </Navbar.Collapse>
        </Container>
      </ONavbar>
      <div className="d-none d-lg-block">
        <HeroContainer fluid className="p-0 bg-danger">
          <Overlay className="d-flex flex-column justify-content-top align-items-center pt-5">
            <img src={logo} width="200px" />
            <h1 className="text-white ns">ตลาดปฐมมงคล</h1>
          </Overlay>
        </HeroContainer>
        {/* <div
          className="w-100 bg-white"
          style={{
            position: "relative",
            height: "250px",
            marginTop: "-90px",
            borderRadius: "50%",
            zIndex: 3,
          }}
        >
          <img
            src={FRUIT_PNG}
            style={{
              position: "relative",
              // width: "400px",
              left: "25%",
              bottom: "93%",
              maxWidth: "400px",
            }}
          />
          <img
            src={VEGET_PNG}
            style={{
              position: "relative",
              // width: "400px",
              left: "30%",
              bottom: "93%",
              maxWidth: "400px",
            }}
          />
        </div> */}
        <Container
          fluid
          className="bg-light"
          style={{
            height: "50px",
          }}
        >
          <Container className="tk d-flex justify-content-end align-items-center h-100">
            <a className="btn btn-text" href="tel://0818516225">
              <Phone /> 081-851-6225
            </a>
            <a className="btn btn-text" href="https://line.me/ti/p/%40pkmarket">
              <Line size={"30px"} style={{ color: "#06C755" }} />
            </a>
            <a
              className="btn btn-text"
              href="https://www.facebook.com/pathommongkolmarket"
            >
              <Facebook size={"30px"} style={{ color: "#4267B2" }} />
            </a>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default CNavbar;

const ONavbar = styled(Navbar)`
  font-family: "Kanit";
  background-color: rgba(0, 0, 0, 0);
`;

const HeroContainer = styled(Container)`
  height: 350px;
  background-image: url(${hero});
`;

const Overlay = styled.div`
  position: relative; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(220, 53, 69, 0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;
