import React from "react";

import { Card, Badge } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { toThaiDateString } from "../../utils";

import LOGO from "../../logo.svg";

const NewsCardItem = ({ post, maxWidth = "350px" }) => {
  const getExcerpt = () => {
    let et = null;

    if (post && post.excerpt && post.excerpt.rendered) {
      et = post.excerpt.rendered;
      et = et.replace(
        '<a class="more-link" href="https://cms.taladpk.com/96-2/">Continue reading <span class="screen-reader-text">Untitled</span></a></p>',
        ""
      );
      et = et.replace("[&hellip;]", "...");
    }

    return et;
  };

  const navigate = useNavigate();
  const imageUrl =
    post && post["_embedded"] && post["_embedded"]["wp:featuredmedia"]
      ? post["_embedded"]["wp:featuredmedia"][0]["source_url"]
      : LOGO;
  const category =
    post &&
    post["_embedded"] &&
    post["_embedded"]["wp:term"] &&
    post["_embedded"]["wp:term"].length &&
    post["_embedded"]["wp:term"][0].length
      ? post["_embedded"]["wp:term"][0][0].name
      : null;
  const title =
    post && post.title && post.title.rendered
      ? post.title.rendered.replace("[&hellip;]", "...")
      : null;

  return (
    <Card
      className="shadow-sm my-1 h-100 mx-1 rounded-5"
      style={{ cursor: "pointer", maxWidth: maxWidth }}
      onClick={() => navigate("/news/" + post.slug)}
    >
      <Card.Img
        variant="bottom"
        className="rounded-0"
        src={imageUrl}
        style={{ maxWidth: maxWidth, Height: "auto" }}
      />
      <Card.Body>
        <Card.Title
          className="text-danger"
          dangerouslySetInnerHTML={{ __html: title }}
        ></Card.Title>
        <Card.Text>
          <Badge className="mx-1" bg="danger">
            {category}
          </Badge>
        </Card.Text>
        <Card.Text
          dangerouslySetInnerHTML={{
            __html: getExcerpt(),
          }}
        ></Card.Text>
      </Card.Body>
      <Card.Footer className="text-muted ts">
        {toThaiDateString(post.date)}
      </Card.Footer>
    </Card>
  );
};

export default NewsCardItem;
