import React from "react";

import MAP_ALL_IMAGE from "../assets/map-all.jpg";
import { Map } from "react-feather";
import { Shop } from "react-bootstrap-icons";
import BuildingCard from "../components/share/BuildingCard";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Building } from "react-bootstrap-icons";
import ShopCard from "../components/share/ShopCard";
import { Container } from "react-bootstrap";

const MapMarket = () => {
  return (
    <>
      <h1 className="fs-1 mx-auto text-center">แผนที่ตลาดปฐมมงคล</h1>
      <div className="d-flex flex-column align-items-center mb-5 bg-secondary pt-5 pb-5">
        <img
          src={MAP_ALL_IMAGE}
          className="rounded img-fluid"
          style={{ maxHeight: "600px" }}
        />
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-around mb-2">
        <a
          className="btn btn-danger btn-lg tk"
          href="https://www.google.co.th/maps/dir//%E0%B8%95%E0%B8%A5%E0%B8%B2%E0%B8%94%E0%B8%9B%E0%B8%90%E0%B8%A1%E0%B8%A1%E0%B8%87%E0%B8%84%E0%B8%A5+(%E0%B8%9D%E0%B8%B1%E0%B9%88%E0%B8%87%E0%B8%95%E0%B8%A5%E0%B8%B2%E0%B8%94%E0%B8%9C%E0%B8%A5%E0%B9%84%E0%B8%A1%E0%B9%89)+129+%E0%B8%96%E0%B8%99%E0%B8%99+Tawarawadee,+Huai+Chorakhe+Sub-district,+Mueang+Nakhon+Pathom+District,+Nakhon+Pathom+73000/@13.8160066,100.066323,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0x30e2e53972e0cd43:0x1a14efa0d7b7cefa!2m2!1d100.0668173!2d13.8159964!3e0"
        >
          <Map /> ขอเส้นทางไปตลาดผลไม้
        </a>
        <a
          className="btn btn-danger btn-lg tk btn-block"
          href="https://www.google.co.th/maps/dir//Talat+Pathom+Mongkol+(Fruits),+129+%E0%B8%96%E0%B8%99%E0%B8%99+Tawarawadee,+Huai+Chorakhe+Sub-district,+Mueang+Nakhon+Pathom+District,+Nakhon+Pathom+73000/@13.8159368,100.0630291,19z/data=!4m9!4m8!1m0!1m5!1m1!1s0x30e2e5eebf4a810f:0x12cc003a2b26adda!2m2!1d100.066817!2d13.8159983!3e0"
        >
          <Map /> ขอเส้นทางไปตลาดผัก
        </a>
      </div>
      <Container className="mt-5 mb-5">
        <h1 className="text-danger">
          <Building /> อาคารผลไม้ 1-12
        </h1>
        <Row className="g-0 gy-0">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((b) => (
            <Col lg={3}>
              <BuildingCard name={`อาคาร ${b}`} />
            </Col>
          ))}
        </Row>
        <h1 className="text-danger mt-5">
          <Building /> อาคารผัก 1-9
        </h1>
        <Row className="g-0 gy-0 mt-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((b) => (
            <Col lg={4}>
              <BuildingCard name={`อาคาร ${b}`} />
            </Col>
          ))}
        </Row>
      </Container>
      {/* <Container>
        <h1 className="text-danger mt-5">
          <Shop /> แผงในตลาด
        </h1>
        <Row className="gy-2 gx-1 mb-5">
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
          <Col lg={3}>
            <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default MapMarket;
