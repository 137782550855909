import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getNewsWithSlug } from "../api";
import { toThaiDateString } from "../utils";
import "../wp.css";

const News = () => {
  const { slug } = useParams();
  const [news, setNews] = useState(null);

  const fetchNews = async (slug) => {
    const { post } = await getNewsWithSlug(slug);

    if (post) {
      setNews(post);
    }
  };

  useEffect(() => {
    fetchNews(slug);
  }, []);

  function getTitle() {
    if (news && news.title && news.title.rendered) {
      return news.title.rendered;
    }
    return null;
  }

  function getContent() {
    if (news && news.content && news.content.rendered) {
      return news.content.rendered;
    }
    return null;
  }

  function getCategory() {
    return news &&
      news["_embedded"] &&
      news["_embedded"]["wp:term"] &&
      news["_embedded"]["wp:term"].length &&
      news["_embedded"]["wp:term"][0].length
      ? news["_embedded"]["wp:term"][0][0].name
      : null;
  }

  function getDate() {
    return news && news.date ? news.date : null;
  }

  return (
    <Container fluid className="mb-3">
      {news && (
        <>
          <h3
            className="text-danger text-center mt-3"
            dangerouslySetInnerHTML={{ __html: getTitle() }}
          ></h3>
          <hr />
          <div
            className="ts fs-6"
            dangerouslySetInnerHTML={{
              __html: getContent(),
            }}
          ></div>
          <hr />
          <Badge bg="danger">{getCategory()}</Badge>{" "}
          <span className="text-muted">{toThaiDateString(getDate())}</span>
        </>
      )}
    </Container>
  );
};

export default News;
