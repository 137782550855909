import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Row, Badge, Container } from "react-bootstrap";
import { Shop } from "react-bootstrap-icons";
import ImageGallery from "react-image-gallery";
import { useParams } from "react-router-dom";
import { API_HOST } from "../api";
import NumberText from "../components/NumberText";
import ShopCard from "../components/share/ShopCard";
import { toThaiDateString } from "../utils";
import LOGO from "../logo.svg";

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    axios
      .get(API_HOST + "/products/" + id)
      .then((response) => {
        if (response.status === 200) setProduct(response.data.product);
      })
      .catch((error) => {});
  }, []);

  const getImages = () => {
    if (Array.isArray(product.images))
      return product.images.map((i) => ({
        original: API_HOST + "/" + i.fileurl,
        thumbnail: API_HOST + "/" + i.fileurl,
      }));

    return [];
  };
  const getType = () => {
    if (product.type === "fruit") return "ผลไม้";
    if (product.type === "vegetable") return "ผัก";

    return null;
  };

  const getDate = () => {
    if (
      product.priceCheckItems &&
      product.priceCheckItems.length &&
      product.priceCheckItems[0].priceCheck &&
      product.priceCheckItems[0].priceCheck.priceAt
    ) {
      return (
        "ราคา วันที่ " +
        toThaiDateString(product.priceCheckItems[0].priceCheck.priceAt)
      );
    }

    return "-";
  };

  const getPrice = () => {
    let minPrice = null;
    let maxPrice = null;
    if (
      product.priceCheckItems &&
      product.priceCheckItems.length &&
      product.priceCheckItems[0].minPrice
    ) {
      minPrice = product.priceCheckItems[0].minPrice;
    }

    if (
      product.priceCheckItems &&
      product.priceCheckItems.length &&
      product.priceCheckItems[0].maxPrice
    ) {
      maxPrice = product.priceCheckItems[0].maxPrice;
    }

    return (
      <>
        ราคาต่ำสุด :{" "}
        <span className="text-danger fw-bold">
          {minPrice ? <NumberText value={minPrice} /> : "-"}
        </span>{" "}
        บาท
        {product.unit && " / " + product.unit}
        <br />
        ราคาสูงสุด :{" "}
        <span className="text-danger fw-bold">
          {maxPrice ? <NumberText value={maxPrice} /> : "-"}
        </span>{" "}
        บาท
        {product.unit && " / " + product.unit}
      </>
    );
  };

  return (
    <>
      {product && (
        <Container>
          <Row className="mt-3 gap-5">
            <Col md={6}>
              <center>
                {getImages().length > 0 ? (
                  <ImageGallery
                    items={getImages()}
                    showBullets
                    showPlayButton={false}
                    additionalClass="text-danger w-75 h-75"
                    showFullscreenButton={false}
                  />
                ) : (
                  <img
                    src={LOGO}
                    alt="ไม่มีรูปภาพ"
                    height={"300px"}
                    width="auto"
                  />
                )}
              </center>
            </Col>
            <Col md={5}>
              <h1 className="text-danger">{product.name}</h1>
              <hr />
              <p className="fs-4">
                <span className="tk">{getPrice()}</span>{" "}
              </p>

              <p className="text-muted">{getDate()}</p>
              <Badge bg="danger">
                <span className="fs-5">{getType()}</span>
              </Badge>
            </Col>
          </Row>
          <hr />
          <h1 className="text-danger">
            <Shop /> แผงที่ขาย
          </h1>
          <Row className="gy-2 gx-1 mb-5">
            <Col className="mt-3">
              <p className="fs-3">เร็วๆ นี้</p>
            </Col>
            {/* <Col lg={3}>
              <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
            </Col>
            <Col lg={3}>
              <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
            </Col>
            <Col lg={3}>
              <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
            </Col>
            <Col lg={3}>
              <ShopCard title="แม่ค้ามะม่วง" description="GGEZ" />
            </Col> */}
          </Row>
        </Container>
      )}
    </>
  );
};

export default Product;
