import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PrivacyNotice = () => {
  const navigate = useNavigate();
  return (
    <Container className="mt-3">
      <h1 className="fs-1 text-danger text-center">
        คำประกาศเกี่ยวกับความเป็นส่วนตัว
      </h1>
      <hr />
      <p>
        เนื่องด้วยตลาดปฐมมงคล จังหวัดนครปฐม ได้จัดทำเว็บไซต์และใช้โดเมนชื่อว่า
        <span className="text-danger fw-bold"> taladpk.com</span>{" "}
        มีการเก็บรวบรวม
        ใช้และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานหรือพ่อค้าแม่ค้าในตลาด
        ตลาดปฐมมงคลจึงจำเป็นต้องจัดทำ คำประกาศเกี่ยวกับความเป็นส่วนตัว (Privacy
        Notice) ฉบับนี้ขึ้น
        เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
        ตระหนักและให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคล
        และถือปฏิบัติอย่างเคร่งครัดในเรื่องการเคารพสิทธิความเป็นส่วนตัวของเจ้าของข้อมูลส่วนบุคคล
        คำประกาศฯ ฉบับนี้จึงจัดทำขึ้นเพื่อให้ท่านในฐานะผู้ใช้งานหรือพ่อค้าแม่ค้า
        (ซึ่งต่อไปในประกาศนี้เรียกว่า "เจ้าของข้อมูลส่วนบุคคล")
        ได้ทราบและเข้าใจรูปแบบ วิธีการ
        และวัตถุประสงค์และความจำเป็นของเว็บไซต์ตลาดปฐมมงคล
      </p>
      <p>
        ดังนั้นตลาดปฐมมงคล ในฐานะผู้ควบคุมข้อมูลส่วนบุคคลตามพระราชบัญญัติฯ
        ดังกล่าว มีหน้าที่ต้องดำเนินการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลจากท่าน
        รวมทั้งกำหนดนโยบายการคุ้มครองข้อมูลส่วนบุคคล
        มาตรการในการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
        ตลอดจนสิทธิตามกฎหมายของท่านในฐานะเจ้าของข้อมูลส่วนบุคคล
        โดยคำประกาศฉบับนี้มีผลบังคับใช้กับเจ้าของข้อมูลส่วนบุคคลที่เป็นผู้ใช้งาน
        ทั้งในการค้นหาข้อมูลราคากลางสินค้า หรือดูข้อมูลของพ่อค้าแม่ค้าในตลาด
      </p>
      <h4 className="text-danger">1. คำนิยาม</h4>
      <p>
        <span className="text-danger fw-bold">"ข้อมูลส่วนบุคคล"</span>{" "}
        หมายความถึง
        ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม
        ซึ่งในคำประกาศเกี่ยวกับความเป็นส่วนตัวนี้จะหมายถึงข้อมูลส่วนบุคคลของผู้ใช้งานเว็บไซต์และข้อมูลของพ่อค้าแม่ค้าที่เปิดเผยบนเว็บไซต์
        ตลาดปฐมมงคล
        <br />
        <span className="text-danger fw-bold">"เว็บไซต์ตลาดปฐมมงคล"</span>{" "}
        หมายความถึง เว็บไซต์ที่ใช้ชื่อโดเมนว่า taladpk.com
        เป็นเว็บไซต์ที่ตลาดปฐมมงคล จัดทำขึ้นเพื่อประชาสัมพันธ์ข้อมูล ราคากลาง
        ข่าวสาร เป็นต้น
        <br />
        <span className="text-danger fw-bold">
          "ผู้ควบคุมข้อมูลส่วนบุคคล"
        </span>{" "}
        หมายถึง ผู้ที่มีอำนาจหน้าที่ตัดสินใจการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานหรือพ่อค้าแม่ค้าในตลาด
        ซึ่งในบริบทของคำประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้จะหมายถึง
        ตลาดปฐมมงคล
        <br />
        <span className="text-danger fw-bold">
          "ผู้ประมวลผลข้อมูลส่วนบุคคล"
        </span>{" "}
        หมายถึง ผู้ซึ่งเป็นบุคคลดำเนินการเกี่ยวกับการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลตามคำสั่งหรือในนามของผู้ควบคุมข้อมูลส่วนบุคคล
        ทั้งนี้
        บุคคลธรรมดาหรือนิติบุคคลซึ่งดำเนินการดังกล่าวไม่เป็นผู้ควบคุมข้อมูลส่วนบุคคล
        <br />
        <span className="text-danger fw-bold">"ผู้ใช้งาน"</span> หมายถึง
        ผู้ที่เข้าใช้เว็บไซต์ตลาดปฐมมงคล
        <br />
        <span className="text-danger fw-bold">"พ่อค้าแม่ค้า"</span> หมายถึง
        ผู้ที่มาเช่าอาคารหรือสถานที่ในตลาดปฐมมงคล เพื่อขายสินค้า
        และยอมเปิดเผยข้อมูลบนเว็บไซต์ตลาดปฐมมงคล
        <br />
      </p>
      <h4 className="text-danger">
        2. วัตถุประสงค์ในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
      </h4>
      <p>
        ผู้ควบคุมข้อมูลส่วนบุคคลดำเนินการเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อวัตถุประสงค์
        ดังต่อไปนี้
        <br />
        <span className="text-danger fw-bold">2.1</span>{" "}
        เพื่อพัฒนาและปรับปรุงการให้บริการให้มีประสิทธิภาพ
        <br />
        <span className="text-danger fw-bold">2.2</span>{" "}
        เพื่อแจ้งและนำเสนอสิทธิประโยชน์ ข้อมูล ข่าวสาร รายการส่งเสริมการขาย
        และข้อเสนอต่างๆ เกี่ยวกับการสมัครเช่าแผง ราคากลางของสินค้า
        การซื้อขายสินค้า หรือการใช้บริการต่างๆ ของตลาดปฐมมงคล
        <br />
        <span className="text-danger fw-bold">2.3</span>{" "}
        เพื่อวัตถุประสงค์อื่นใดที่กฎหมายกำหนดให้เก็บรวบรวม ใช้ และเปิดเผย
        โดยไม่ต้องได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล
      </p>
      <h4 className="text-danger">
        3. หลักการในการเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล
      </h4>
      <p>
        ผู้ควบคุมข้อมูลส่วนบุคคลพิจารณากำหนดฐานกฎหมายในการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลตามความเหมาะสมและตามบริบทของการให้บริการ
        โดยฐานกฎหมายหลักที่ผู้ควบคุมข้อมูลใช้ในการประมวลผลข้อมูลส่วนบุคคลมีดังนี้
        <br />
        <span className="text-danger fw-bold">3.1</span>{" "}
        ความยินยอมของเจ้าของข้อมูลส่วนบุคคล เพื่อการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ควบคุมข้อมูลที่จำเป็นต้องได้รับความยินยอม
        โดยได้แจ้งวัตถุประสงค์ของการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลก่อนหรือขณะขอความยินยอม อาทิเช่น
        การเก็บข้อมูลการค้นหาราคาสินค้า
        การเปิดเผยข้อมูลของพ่อค้าแม่ค้าผู้ซึ่งขายสินค้าใด ๆ เป็นต้น
        ทั้งนี้ให้รวมถึงการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหวซึ่งไม่เข้าข้อยกเว้นตามกฎหมายด้วย
        <br />
        <span className="text-danger fw-bold">3.2 </span>
        เพื่อบรรลุวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยหรือสถิติ
        เพื่อการวิเคราะห์ข้อมูลสำหรับการศึกษาวิจัยหรือสถิติ
        ตลอดจนการประมวลผลข้อมูลเพื่อให้ได้มาซึ่งผลลัพธ์ของการศึกษาวิจัยและสถิติดังกล่าว
        อย่างไรก็ตามการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลเพื่อประโยชน์ในการศึกษาวิจัยและสถิตินั้นจะอยู่ควบคู่ไปกับฐานความยินยอมในข้อ
        3.1
        และผู้ควบคุมข้อมูลส่วนบุคคลได้จัดให้มีมาตรการการคุ้มครองข้อมูลส่วนบุคคลที่เหมาะสมตามที่ปรากฏในข้อ
        10. ของคำประกาศฯ ฉบับนี้
      </p>
      <h4 className="text-danger">
        4. ข้อมูลส่วนบุคคลของผู้ใช้งานที่โครงการฯ เก็บรวบรวม ใช้ และเปิดเผย
      </h4>
      <p>
        <span className="text-danger fw-bold">
          4.1. แหล่งข้อมูลและรายการข้อมูลส่วนบุคคลที่เก็บรวบรวม มีดังนี้
        </span>
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">4.1.1 </span>
        เก็บข้อมูลพ่อค้าแม่โดยตรง จากพนักงานเจ้าหน้าที่ของตลาดปฐมมงคล
        และได้ให้ทางพ่อค้าแม่ค้ายินยอมเป็นเอกสารหลักฐาน
        ลงลายมือชื่อไว้ถูกต้องแล้ว) ซึ่งประกอบด้วยข้อมูลดังนี้
        <br /> - ชื่อร้านค้า
        <br /> - เบอร์โทรศัพท์
        <br /> - สินค้าที่ขาย
        <br /> - อาคารและเบอร์ล็อคที่เช่าในตลาดปฐมมงคล
        <br /> &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">4.1.2 </span>
        เก็บข้อมูลผู้เข้าใช้งานจากเว็บไซต์ตลาดปฐมมงคล
        ผ่านการค้นหาข้อมูลราคากลางสินค้า หรืออื่น ๆ เป็นต้น โดยเก็บรวบรวม เช่น
        คำค้นหา ประเภทสินค้าที่ค้นหา การเรียงลำดับสินค้าที่แสดง
        <br />
        <span className="text-danger fw-bold">
          4.2. จุดประสงค์การใช้งานข้อมูลส่วนบุคคล
        </span>
        <br /> - เพื่อนำเสนอร้านค้าให้แก่ ผู้เข้าใช้งานเว็บไซต์ตลาดปฐมมงคล
        <br /> - เพื่อนำเสนอข้อมูลราคากลางของสินค้า หรือ ข่าวสาร
        ผู้เข้าใช้งานเว็บไซต์ตลาดปฐมมงคล
        <br /> - หรือเพื่อจุดประสงค์อื่น ๆ ใด ให้บรรลุวัตถุประสงค์ในข้อ 2.
        <br /> อนึ่ง สำหรับการจัดเก็บคุกกี้ (Cookie) ของเว็บไซต์ตลาดปฐมมงคล
        จะดำเนินการตาม{" "}
        <span
          className="text-danger fw-bold"
          onClick={() => navigate("/cookiePolicy")}
        >
          นโยบายคุกกี้
        </span>{" "}
        ซึ่งปรากฎตาม taladpk.com
      </p>
      <h4 className="text-danger">5. การเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน</h4>
      <p>
        <span className="text-danger fw-bold">5.1</span>{" "}
        ผู้ควบคุมข้อมูลส่วนบุคคลจะไม่เปิดเผยข้อมูล
        ให้กับบุคคลที่ไม่ได้เกี่ยวข้องกับการกระทำใด ๆ
        เพื่อให้บรรลุวัตถุประสงค์ในข้อ 2. เว้นแต่เป็นการปฏิบัติตามกฎหมาย
        หรือเป็นสิทธิของผู้ควบคุมข้อมูลอันพึงกระทำได้ตามกฎหมาย
        <br />
        <span className="text-danger fw-bold">5.2</span>{" "}
        เพื่อให้บรรลุวัตถุประสงค์ในข้อ 2.
        ผู้ควบคุมข้อมูลส่วนบุคคลอาจเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานต่อบุคคลหรือนิติบุคคลดังต่อไปนี้
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">5.2.1</span> ผู้ให้บริการคลาวด์
        (Cloud Platform) คือ Digital Ocean
        เพื่อให้ผู้ใช้สามารถงานใช้เว็บไซต์ตลาดปฐมมงคลได้
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">5.2.2</span>{" "}
        ผู้ประมวลผลข้อมูลส่วนบุคคล เพื่อให้ผู้ประมวลผลดำเนินการใด ๆ
        เพื่อให้บรรลุหรือเป็นไป ตามวัตถุประสงค์ในข้อ 2.
        หรือการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคล ในข้อ 9. หรือ
        ดำเนินการตามที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลมอบหมาย
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">5.2.3</span> บุคคลอื่น
        ที่จำเป็นเพื่อการให้บริการแก่ผู้ใช้งาน รวมถึงดำเนินการใด ๆ
        เพื่อให้บรรลุหรือเป็นไปตามวัตถุประสงค์ในข้อ 2.
        และประมวลผลข้อมูลส่วนบุคคล
      </p>
      <h4 className="text-danger">
        6. การส่งหรือโอนข้อมูลส่วนบุคคลของผู้ใช้งานไปยังต่างประเทศ
      </h4>
      <p>
        ผู้ควบคุมข้อมูลส่วนบุคคลอาจมีการโอนข้อมูลของผู้ใช้งานหรือพ่อค้าแม่ค้าไปยังผู้ให้บริการคลาวด์
        ทั้งนี้ เพื่อประโยชน์ของผู้ใช้งานและพ่อค้าแม่ค้า
        หรือเพื่อให้บรรลุวัตถุประสงค์ในข้อ 2.
        ซึ่งข้อมูลดังกล่าวจะได้รับความคุ้มครองตามมาตรฐานการคุ้มครองข้อมูลส่วนบุคคล
      </p>
      <h4 className="text-danger">
        7. สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ของผู้ใช้งาน
      </h4>
      <p>
        <span className="text-danger fw-bold">7.1</span> สิทธิในการเข้าถึง
        รับสำเนาและขอให้เปิดเผยที่มาของข้อมูลส่วนบุคคลของผู้ใช้งานที่ผู้ควบคุมข้อมูล
        เก็บรวบรวมอยู่
        เว้นแต่กรณีที่ผู้ควบคุมข้อมูลส่วนบุคคลมีสิทธิปฏิเสธคำขอของผู้ใช้งานตามกฎหมายหรือคำสั่งศาล
        หรือกรณีที่คำขอของผู้ใช้งานจะมีผลกระทบที่อาจก่อให้เกิดความเสียหายต่อสิทธิและเสรีภาพของบุคคลอื่น
        <br />
        <span className="text-danger fw-bold">7.2 </span>
        สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของผู้ใช้งานที่ไม่ถูกต้องหรือไม่ครบถ้วน
        เพื่อให้มีความถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
        <br />
        <span className="text-danger fw-bold">7.3 </span>
        สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลของผู้ใช้งานในกรณีหนึ่งกรณีใดดังต่อไปนี้
        (กรณีที่ผู้ใช้งานสามารถใช้สิทธิได้ตามมาตรา 34
        และสอดคล้องกับงานของผู้ใช้งาน)
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">7.3.1 </span>
        เมื่ออยู่ในช่วงเวลาที่ผู้ควบคุมข้อมูลส่วนบุคคลทำการตรวจสอบตามคำร้องขอของผู้ใช้งานให้แก้ไขข้อมูลส่วนบุคคลของผู้ใช้งานให้ถูกต้อง
        สมบูรณ์และเป็นปัจจุบัน
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">7.3.2 </span>
        ข้อมูลส่วนบุคคลของผู้ใช้งานถูกเก็บรวบรวม
        ใช้หรือเปิดเผยโดยมิชอบด้วยกฎหมาย
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">7.3.3 </span>
        เมื่อข้อมูลส่วนบุคคลของผู้ใช้งานหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ของผู้ควบคุมข้อมูลส่วนบุคคลซึ่งได้แจ้งไว้ในการเก็บรวบรวม
        แต่ผู้ใช้งานประสงค์ให้ผู้ควบคุมข้อมูลส่วนบุคคลเก็บรักษาข้อมูลนั้นต่อไปเพื่อประกอบการใช้สิทธิตามกฎหมายของผู้ใช้งาน
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <span className="text-danger fw-bold">7.3.4 </span>
        เมื่ออยู่ในช่วงเวลาที่ผู้ควบคุมข้อมูลส่วนบุคคลกำลังพิสูจน์ให้ผู้ใช้งานเห็นถึงเหตุอันชอบด้วยกฎหมายในการเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้งาน
        หรือตรวจสอบความจำเป็นในการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อประโยชน์สาธารณะ
        อันเนื่องมาจากการที่ผู้ใช้งานได้ใช้สิทธิคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
        <br />
        <span className="text-danger fw-bold">7.4 </span>
        สิทธิในการคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน
        เว้นแต่กรณีที่ผู้ควบคุมข้อมูลส่วนบุคคลมีเหตุในการปฏิเสธคำขอของผู้ใช้งานโดยชอบด้วยกฎหมาย
        เช่น คำร้องขอใช้สิทธิที่ไม่สุจริต/ไม่สมเหตุสมผล/ไม่สามารถปฏิบัติได้จริง
        หรืออาจปฏิเสธคำร้องขอของผู้ใช้งานตามหลักเกณฑ์อื่นใดที่กฎหมายกำหนด
        เป็นต้น
        <br />
        <span className="text-danger fw-bold">7.5</span>{" "}
        สิทธิในการเพิกถอนความยินยอม
        ในกรณีที่ผู้ควบคุมข้อมูลส่วนบุคคลอาศัยความยินยอมในการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่าน
        ท่านมีสิทธิในการเพิกถอนความยินยอมในการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมไว้แก่ผู้ควบคุมข้อมูลส่วนบุคคลได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่ในความครอบครองของผู้ควบคุมข้อมูล
        <br />
        <span className="text-danger fw-bold">7.6</span>{" "}
        สิทธิในการลบข้อมูลส่วนบุคคล
        ท่านอาจขอให้ผู้ควบคุมข้อมูลส่วนบุคคลลบหรือทำให้ข้อมูลส่วนบุคคลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ในกรณีที่ข้อมูลส่วนบุคคลของท่านหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่กำหนดในการเก็บรวบรวม
        ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
        หรือเมื่อท่านถอนความยินยอมที่เป็นบานในการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคล หรือเมื่อท่านได้คัดค้านการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่านตามข้อ 7.4 หรือเมื่อการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่านไม่ชอบด้วยกฎหมาย
        หรือเมื่อการศึกษาวิจัยและสถิติได้สิ้นสุดลง
      </p>
      <h4 className="text-danger">8. การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</h4>
      <p>
        ก่อนที่ผู้ควบคุมข้อมูลส่วนบุคคลจะทำการเก็บรวบรวมข้อมูล ใช้
        หรือเปิดเผยข้อมูลของผู้ใช้งาน
        ผู้ควบคุมข้อมูลส่วนบุคคลจะทำการขอความยินยอมจากผู้ใช้งานก่อน (Consent)
        โดยการขอความยินยอม ผู้ควบคุมข้อมูลส่วนบุคคลจะทำโดยชัดเจน
        ชัดแจ้งและไม่คลุมเครือ
        ผู้ควบคุมข้อมูลส่วนบุคคลจะเปิดเผยข้อมูลส่วนบุคคลต่อเมื่อได้รับคำร้องขอจากเจ้าของข้อมูลส่วนบุคคล
        ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม หรือผู้อนุบาลหรือผู้พิทักษ์ตามกฎหมาย
        โดยส่งคำร้องขอผ่านทางจดหมายอิเล็กทรอนิกส์ ที่ระบุท้ายประกาศ ทั้งนี้
        ผู้ควบคุมข้อมูลส่วนบุคคลอาจปฏิเสธสิทธิตามวรรคสองได้ตามกรณีที่มีกฎหมายกำหนด
        หรือในกรณีที่ข้อมูลส่วนบุคคลของผู้ใช้งานถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวผู้ใช้งานได้
        หรือผู้ใช้งานไม่สามารถพิสูจน์ได้จริงว่าข้อมูลส่วนบุคคลดังกล่าวเป็นของผู้ใช้งาน
        ในกรณีที่เจ้าของข้อมูล ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม
        หรือผู้พิทักษ์ตามกฎหมายมีการคัดค้านการจัดเก็บ ความถูกต้อง หรือการกระทำใด
        ๆ เช่น การแจ้งดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคล ตลาดปฐมมงคล
        จะดำเนินการบันทึกหลักฐานคำคัดค้านดังกล่าวไว้เป็นหลักฐานด้วย
      </p>
      <h4 className="text-danger">9. การมีส่วนร่วมของเจ้าของข้อมูลส่วนบุคคล</h4>
      <p>
        ตลาดปฐมมงคลจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ตามข้อ
        2.
        ตามระยะเวลาที่มีสัญญาหรือนิติสัมพันธ์ตามกฎหมายที่บังคับใช้ระหว่างท่านและตลาดปฐมมงคล
        เว้นแต่กฎหมายจะอนุญาตให้มีระยะเวลาการเก็บรักษาที่นานขึ้น
        <br />
        ตลาดปฐมมงคลจะดำเนินการลบหรือทำลายข้อมูลส่วนบุคคล
        หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลได้ตามมาตรฐานการทำลายข้อมูลของตลาดปฐมมงคล
        เมื่อพ้นระยะเวลาจัดเก็บ
        หรือตลาดปฐมมงคลไม่มีสิทธิหรือไม่สามารถอ้างฐานในการประมวลผลข้อมูลส่วนบุคคลของท่านแล้ว
      </p>
      <h4 className="text-danger">
        10. การรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคล
      </h4>
      <p>
        เพื่อให้มั่นใจได้ว่าบุคลากรเหล่านั้นจะปฏิบัติตามข้อกำหนดของคำประกาศฉบับนี้ตามนโยบายความเป็นส่วนตัวของผู้ควบคุมข้อมูลส่วนบุคคล
        และของกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคลที่ใช้บังคับ
        ข้อมูลส่วนบุคคลทั้งหลายทั้งปวงที่ผู้ใช้งานได้นำเข้าสู่เว็บไซต์ตลาดปฐมมงคล
        จะเข้าถึงได้โดยบุคลากรของผู้ควบคุมข้อมูลส่วนบุคคลที่มีหน้าที่ที่เกี่ยวข้อง
        และได้รับอนุญาตแล้วเท่านั้น หรือให้แก่ผู้ที่มีหน้าที่ที่เกี่ยวข้อง
        ที่ได้รับอนุญาตหรือมอบหมายจากผู้ควบคุมข้อมูลส่วนบุคคลให้เข้าถึงข้อมูลส่วนบุคคลดังกล่าวได้
        ทั้งนี้ เพื่อการดำเนินการตามวัตถุประสงค์ที่ระบุไว้ในนโยบายนี้เท่านั้น
        อย่างไรก็ตาม
        ไม่มีวิธีการจัดเก็บข้อมูลทางอิเล็กทรอนิกส์ใดที่จะมีความปลอดภัยที่สมบูรณ์
        ผู้ควบคุมข้อมูลส่วนบุคคลมีมาตรการในการรักษาความมั่นคงปลอดภัยข้อมูลส่วนบุคคลของผู้ใช้งานอย่างเหมาะสม
        ทั้งในเชิงเทคนิคและการบริหารจัดการ เพื่อป้องกันมิให้ข้อมูลสูญหาย
        หรือมีการเข้าถึง ทำลาย ใช้ เปลี่ยนแปลง แก้ไข
        หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต นอกจากนี้
        ผู้ควบคุมข้อมูลส่วนบุคคลได้กำหนดให้มีนโยบายการคุ้มครองข้อมูลส่วนบุคคลขึ้นโดยประกาศให้ทราบกันโดยทั่วกัน
        พร้อมแนวทางปฏิบัติเพื่อให้เกิดความมั่นคงปลอดภัยในการเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคล โดยธำรงไว้ซึ่งความเป็นความลับ
        (Confidentiality) ความถูกต้องครบถ้วน (Integrity) สภาพพร้อมใช้งาน
        (Availability) ของข้อมูลส่วนบุคคล ทั้งนี้ได้จัดให้มีการพิสูจน์ตัวตน
        (Authentication)
        เพื่อให้มั่นใจได้ว่าสิทธิการเข้าใช้บริการหรือฟังก์ชันที่เกี่ยวข้องกับข้อมูลส่วนบุคคลต้องผ่านกระบวนการยืนยันตัวตน
        อีกทั้งเมื่อยืนยันตัวตนแล้วได้จัดให้มีการกำหนดสิทธิ (Authorization)
        การเข้าถึงข้อมูลส่วนบุคคล เท่าที่จำเป็นกับบริการหรือฟังก์ชันเท่านั้น
      </p>
      <h4 className="text-danger">
        11. ความรับผิดชอบของบุคคลซึ่งประมวลผลข้อมูลส่วนบุคคล
      </h4>
      <p>
        ผู้ควบคุมข้อมูลส่วนบุคคลได้กำหนดให้เจ้าหน้าที่เฉพาะผู้ที่มีอำนาจหน้าที่เกี่ยวข้องในการจัดเก็บรวบรวม
        ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของกิจกรรมการประมวลผลนี้เท่านั้นที่จะสามารถเข้าถึงข้อมูลส่วนบุคคลของผู้ใช้งานได้
        โดยตลาดปฐมมงคล
        จะดำเนินการให้เจ้าหน้าที่ปฏิบัติตามประกาศนี้อย่างเคร่งครัด
      </p>
      <h4 className="text-danger">
        12. การเปลี่ยนแปลงแก้ไขคำประกาศเกี่ยวกับความเป็นส่วนตัว
      </h4>
      <p>
        ในการปรับปรุงหรือเปลี่ยนแปลงประกาศนี้
        ผู้ควบคุมข้อมูลส่วนบุคคลอาจพิจารณาแก้ไขเปลี่ยนแปลงตามที่เห็นสมควร
        และจะทำการแจ้งให้ผู้ใช้งานทราบผ่านเว็บไซต์ตลาดปฐมมงคล ภายใต้หัวข้อ
        คำประกาศเกี่ยวกับความเป็นส่วนตัว
        โดยมีวันที่ของเวอร์ชันล่าสุดกำกับอยู่ตอนท้าย อย่างไรก็ดี
        ผู้ควบคุมข้อมูลส่วนบุคคลขอแนะนำให้ผู้ใช้งานโปรดตรวจสอบเพื่อรับทราบประกาศฉบับใหม่อย่างสม่ำเสมอ
        โดยเฉพาะก่อนที่ผู้ใช้งานจะเปิดเผยข้อมูลส่วนบุคคลแก่ผู้ควบคุมข้อมูลส่วนบุคคล
        โดยในการเข้าใช้งานเว็บแอปพลิเคชันนี้ของผู้ใช้งาน
        ถือเป็นการรับทราบตามข้อตกลงในประกาศนี้ ทั้งนี้
        โปรดหยุดการใช้งานหากผู้ใช้งานไม่เห็นด้วยกับข้อตกลงในประกาศฉบับนี้
        หากผู้ใช้งานยังคงใช้งานต่อไปภายหลังจากที่ประกาศนี้มีการแก้ไขและนำขึ้นประกาศในช่องทางข้างต้นแล้ว
        จะถือว่าผู้ใช้งานได้รับทราบการเปลี่ยนแปลงดังกล่าวแล้ว
      </p>
      <h4 className="text-danger">13. การติดต่อสอบถาม</h4>
      <p>
        ผู้ใช้งานสามารถติดต่อสอบถามเกี่ยวกับประกาศฉบับนี้ได้ที่
        เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล (Data Protection Officer: DPO) ชื่อ:
        นายxxx xxxx
        <br />
        สถานที่ติดต่อ: "ตลาดปฐมมงคล" 129 ถนนทวาราวดี ห้วยจรเข้ เมืองนครปฐม
        นครปฐม 73000
        <br />
        ช่องทางการติดต่อ: โทรศัพท์ xxx-xxx-xxxx อีเมล xxx@xx.com
      </p>
      <p className="text-muted">อัปเดตล่าสุด 11 กรกฎาคม 2565</p>
    </Container>
  );
};

export default PrivacyNotice;
